import { useStore } from '@sar/framework/hooks'
import { useRouteMatch } from 'react-router'

import { Redirect } from 'react-router-dom'
import { Header, Footer, Menu } from 'components'
import { Layout } from 'antd'

export default (Target: React.FC<any>) => function Layouter() {
  const store = useStore()
  const route = useRouteMatch()

  if(/\/login/g.test(route.url)) {
    return <Target store={store}/>
  }

  if(store.identity.uid == null){
    return <Redirect to='/login' />
  }

  return <Layout style={{ minHeight: '100vh' }}>
    <Layout.Sider>
      <h1 className='text-xl text-center text-white pt-6 py-4 m-0 '>昊源诺信</h1>
      <Menu menus={store.config.menus} />
    </Layout.Sider>

    <Layout>
      <Layout.Header>
        <Header store={store} route={route} />
      </Layout.Header>

      <Layout.Content>
        <Target store={store} route={route} />
      </Layout.Content>

      <Layout.Footer>
        <Footer data={store.config.env} />
      </Layout.Footer>
    </Layout>
  </Layout>
}
