import React from 'react'
import Table from '@sar/component/src/table'

import api from '@sar/http'
import file from 'file-saver'
import service from 'services/product-file'

import { UploadMultiple } from 'components'
import { ModalForm, ProFormSelect } from '@ant-design/pro-form'

function Create({ store, visible, handles, onVisibleChange }) {
  return <ModalForm
    width={600}
    title='创建'
    visible={visible}
    onVisibleChange={onVisibleChange}
    modalProps={{ maskClosable: false, destroyOnClose: true }}
    onFinish={params => service(api).create(params).then(() => handles.current.reload()).then(R.T)}
  >
    <ProFormSelect
      width='md'
      name='product'
      label='产品'
      rules={[{ required: true }]}
      request={() => api.get('/product/selectAll').then(R.path([ 'data', 'list' ])).then(R.map<any, any>(e => ({ label: e.title, value: e.id })))}
    />

    <UploadMultiple
      type='file'
      name='url'
      label='资料'
      max={1}
      rules={[{ required: true }]}
      fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
      transform={value => ({ url: R.head<any>(value).url, fileName: R.head<any>(value).name })}
    />
  </ModalForm>
}

async function fetchData(params) {
  const result = await service(api).list({
    params: Object.assign(params, {
      page: params.current,
      size: params.pageSize,
    })
  })

  return {
    success: true,
    data: result.data.list,
    total: result.data.count,
  }
}

export default function View ({ store }) {
  const columns = [
    {
      dataIndex: 'productId',
      valueType: 'select',
      hideInTable: true,
      formItemProps: () => ({ label: false }),
      fieldProps: () => ({ placeholder: '根据产品搜索' }),
      request: () => api.get('/product/selectAll').then(R.path([ 'data', 'list' ])).then(R.map<any, any>(e => ({ label: e.title, value: e.id }))),
    },
    {
      title: '产品',
      dataIndex: 'productName',
      hideInSearch: true,
    },
    {
      title: '文件名称',
      dataIndex: 'fileName',
      hideInSearch: true,
      render: elm => <span className='inline-block py-0.5 px-2 rounded border border-purple-600 text-purple-600 bg-purple-100'>{elm}</span>,
    },
    {
      title: '下载链接',
      dataIndex: 'url',
      hideInSearch: true,
      render: (elm, data) => <a className='underline hover:underline' title='点击下载' onClick={e => file.saveAs(elm, data.fileName)}>{elm}</a>,
    },
  ]

  const actions = [
    {
      name: '创建',
      type: 'trigger',
      form: props => <Create {...props} store={store} />,
    },
  ]

  const rowActions = [
    {
      danger: true,
      name: '删除',
      confirmType: 'modal',
      confirmMessage: '您确定删除这条数据吗？',
      onConfirm: (data, handles) => service(api).delete({ id: data.id }).then(() => handles.current.reload()),
    },
  ]

  return <div className='m-4'>
    <Table
      title='产品资料'
      request={fetchData}
      columns={columns}
      actions={actions}
      rowActions={rowActions}
      search={{ labelWidth: 'auto', span: store.config.responsive }}
    />
  </div>
}
