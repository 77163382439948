import { Menu, Dropdown } from 'antd'

export default function View ({ store, route }) {
  const items = [
    {
      key: 'logout',
      label: '注销登录',
      icon: <span className='text-gray-700 fa fa-sign-out mr-1' />,
    },
  ]

  return <div className='flex justify-end pr-4'>
    <Dropdown overlay={<Menu items={items} onClick={e => Promise.resolve(store.identity.clear()).then(() => store.navigate.absolute('/login'))}/>}>
      <div className='text-lg cursor-pointer mr-3'>
        <span className='fa fa-commenting-o' />
        <span className='fa fa-cog mx-1' />
        <span className='text-base'>管理员</span>
      </div>
    </Dropdown>
  </div>
}
