import env from '../profiles/env.config'
import menus from '../profiles/menu.config'
import constants from '../profiles/constant.config'
import responsive from '../profiles/responsive.config'

export default function useConfig() {
  return {
    env,
    menus,
    constants,
    responsive,
    loadImg: (src: string) => `${R.defaultTo('', env.prefix)}${src}`,
    getConstantValues: (type: string) => R.propOr<any, any, any>([], type, constants),
    getConstantPairs: (type: string) => R.propOr<any, any, any>([], type, constants).reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {}),
    getConstantEnums: (type: string) => R.propOr<any, any, any>([], type, constants).reduce((prev, curr) => ({ ...prev, [curr.value]: curr.description }), {}),
    getConstantDescriptByValue: (type: string, value: string | number) => R.propOr<any, any, any>([], type, constants).find(data => data.value === value)?.description,
  }
}
