import api from '@sar/http'
import service from 'services/tools'

import { v4 } from 'uuid'
import { useMemo } from 'react'
import { useRequest, useBoolean } from 'ahooks'

export default function useOSS() {
  const [ init, handle ] = useBoolean(false)
  const { data, loading } = useRequest<any, any>(() => service(api).oss().then(R.prop('data')), { pollingInterval: 60 * 30 * 1000 })

  const client = useMemo(() => {
    if(data == null){
      return null
    }

    handle.setTrue()

    return new OSS.Wrapper({
      secure: data.secure,
      bucket: data.bucket,
      region: data.region,
      stsToken: data.Credentials.securityToken,
      accessKeyId: data.Credentials.accessKeyId,
      accessKeySecret: data.Credentials.accessKeySecret,
    })
  }, [ data ])

  const upload = async file => {
    const result = await client.multipartUpload(`${v4()}.${file.name.split('.').pop()}`, file)

    return {
      name: file.name,
      etag: result.etag,
      url: `//${data.endpoint}/${result.name}`,
    }
  }

  return {
    init,
    data,
    upload,
    client,
    loading,
  }
}
