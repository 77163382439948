import api from '@sar/http'
import service from 'services/user'

import { useState } from 'react'
import { useRequest } from 'ahooks'

import { LoginForm } from '@ant-design/pro-components'
import { ProFormText } from '@ant-design/pro-form'

export default function({ store }) {
  const doLogin = useRequest(async data => {
    const result = await service(api).login({
      name: data.username,
      password: data.password,
    })

    store.identity.dispatch('uid', result.data.id)
    store.identity.dispatch('token', result.data.token)
  }, { manual: true })

  return <div className='flex flex-col h-screen items-center justify-center bg-gray-800'>
    <div className='flex flex-col justify-center space-y-4 bg-white p-8 pt-4'>
      <LoginForm title='昊源信诺' subTitle='官网管理后台' contentStyle={{ width: 360 }} onFinish={e => doLogin.runAsync(e).then(() => store.navigate.dispatch('/setting/banners'))}>
        <ProFormText
          width='xl'
          name='username'
          placeholder='用户名'
          rules={[{ required: true, message: '请输入用户名!' }]}
          fieldProps={{ size: 'large', prefix: <span className='fa fa-user text-gray-600 px-2' /> }}
        />

        <ProFormText.Password
          width='xl'
          name='password'
          placeholder='登录密码'
          rules={[{ required: true, message: '请输入登录密码!' }]}
          fieldProps={{ size: 'large', prefix: <span className='fa fa-lock text-gray-600 px-2' /> }}
        />
      </LoginForm>
    </div>
  </div>
}
