export default {
  Boolean: [
    {
      name: 'FALSE',
      value: 1,
      description: '否',
    },
    {
      name: 'TRUE',
      value: 0,
      description: '是',
    },
  ],
  InfoTypes: [
    {
      name: 'company',
      value: 1,
      description: '公司资讯',
    },
    {
      name: 'business',
      value: 2,
      description: '行业资讯',
    },
  ],
}
