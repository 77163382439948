export default api => ({
  list (options?) {
    return api.get(`/banner/selectAll`, options)
  },

  detail (options?) {
    return api.get(`/banner/selectById`, options)
  },

  create (options?) {
    return api.post(`/banner/save`, options)
  },

  update (options?) {
    return api.post(`/banner/update`, options)
  },

  delete (options?) {
    return api.post(`/banner/delete`, options)
  },

  topping(options?) {
    return api.post(`/banner/topping`, options)
  },
})
