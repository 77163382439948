import React from 'react'
import Table from '@sar/component/src/table'

import api from '@sar/http'
import service from 'services/banner'

import { Upload } from 'components'
import { ModalForm, ProFormText } from '@ant-design/pro-form'

function Create({ store, visible, handles, onVisibleChange }) {
  return <ModalForm
    width={600}
    title='添加图片'
    visible={visible}
    onVisibleChange={onVisibleChange}
    modalProps={{ maskClosable: false, destroyOnClose: true }}
    onFinish={params => service(api).create(params).then(() => handles.current.reload()).then(R.T)}
  >
    <ProFormText
      name='desc'
      label='备注'
      placeholder='图片备注, 可以为空'
    />

    <ProFormText
      name='link'
      label='链接'
      placeholder='点击后的跳转链接, 可以为空'
    />

    <Upload
      name='url'
      label='图片'
      max={1}
      rules={[{ required: true }]}
      fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
      transform={value => ({ url: value?.url ? value.url : value })}
      convertValue={value => value?.url ? value : value ? ({ url: value }) : null}
    />
  </ModalForm>
}

function Update({ data, store, visible, handles, onVisibleChange }) {
  return <ModalForm
    width={600}
    title='修改'
    visible={visible}
    onVisibleChange={onVisibleChange}
    modalProps={{ maskClosable: false, destroyOnClose: true }}
    request={() => service(api).detail({ params: { id: data.id } }).then(R.prop('data'))}
    onFinish={e => service(api).update({ ...e, id: data.id }).then(() => handles.current.reload()).then(R.T)}
  >
    <ProFormText
      name='desc'
      label='备注'
      placeholder='图片备注, 可以为空'
    />

    <ProFormText
      name='link'
      label='链接'
      placeholder='点击后的跳转链接, 可以为空'
    />

    <Upload
      name='url'
      label='图片'
      max={1}
      rules={[{ required: true }]}
      fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
      transform={value => ({ url: value?.url ? value.url : value })}
      convertValue={value => value?.url ? value : value ? ({ url: value }) : null}
    />
  </ModalForm>
}

async function fetchData(params) {
  const result = await service(api).list({
    params: {
      page: params.current,
      size: params.pageSize,
    }
  })

  return {
    success: true,
    data: result.data.list,
    total: result.data.count,
  }
}

export default function View({ store }) {
  const columns = [
    {
      title: '图片',
      dataIndex: 'url',
      hideInSearch: true,
      render: e => <img className='w-16 h-16 object-cover' src={e}/>,
    },
    {
      title: '链接',
      dataIndex: 'link',
      hideInSearch: true,
    },
    {
      title: '备注',
      dataIndex: 'desc',
      hideInSearch: true,
    },
  ]

  const actions = [
    {
      name: '创建',
      type: 'trigger',
      form: props => <Create {...props} store={store} />,
    },
  ]

  const rowActions = [
    {
      name: '修改',
      form: props => <Update {...props} store={store} />,
    },
    {
      name: '置顶',
      confirmType: 'modal',
      confirmMessage: '您确定置顶这张图片吗？',
      onConfirm: (data, handles) => service(api).topping({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      danger: true,
      name: '删除',
      confirmType: 'modal',
      confirmMessage: '您确定删除这张图片吗？',
      onConfirm: (data, handles) => service(api).delete({ id: data.id }).then(() => handles.current.reload()),
    },
  ]

  return <div className='m-4'>
    <Table
      title='首页图片管理'
      request={fetchData}
      columns={columns}
      actions={actions}
      rowActions={rowActions}
    />
  </div>
}
