import React from 'react'
import Table from '@sar/component/src/table'

import api from '@sar/http'
import service from 'services/news'

import { Upload, Template } from 'components'
import ProForm, { ProFormSelect, ProFormText, ProFormTextArea, ProFormDatePicker } from '@ant-design/pro-form'

async function fetchData(params) {
  const result = await service(api).list({
    params: {
      type: params.type,
      page: params.current,
      size: params.pageSize,
    }
  })

  return {
    success: true,
    data: result.data.list,
    total: result.data.count,
  }
}

function Create({ store }) {
  return <div className='m-4 p-4 bg-white'>
    <ProForm onFinish={params => service(api).create(params).then(() => store.navigate.absolute('/information/manager'))} >
      <Upload
        name='image'
        label='图片(推荐宽高 960 * 740)'
        max={1}
        rules={[{ required: true }]}
        fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
        transform={value => ({ image: value?.url ? value.url : value })}
        convertValue={value => value?.url ? value : value ? ({ url: value }) : null}
      />

      <ProForm.Group>
        <ProFormText
          width='md'
          name='title'
          label='标题'
          rules={[{ required: true }]}
        />

        <ProFormSelect
          width='md'
          name='type'
          label='类型'
          rules={[{ required: true }]}
          valueEnum={store.config.getConstantEnums('InfoTypes')}
        />
      </ProForm.Group>

      <ProFormDatePicker
        width='sm'
        name='releaseTime'
        label='发布时间'
        rules={[{ required: true }]}
      />

      <ProFormTextArea
        width='xl'
        name='desc'
        label='简介'
        fieldProps={{ rows: 6 }}
        rules={[{ required: true }]}
      />

      <Template
        name='content'
        label='内容'
        rules={[{ required: true }]}
        fieldProps={{ init: store.oss.init, handler: (file: File) => store.oss.upload(file) }}
      />
    </ProForm>
  </div>
}

function Update({ route, store }) {
  return <div className='m-4 p-4 bg-white'>
    <ProForm request={() => service(api).detail({ params: { id: route.params.id } }).then(R.prop('data'))} onFinish={e => service(api).update(({ ...e, id: route.params.id })).then(() => store.navigate.absolute('/information/manager'))} >
      <Upload
        name='image'
        label='图片(推荐宽高 960 * 740)'
        max={1}
        rules={[{ required: true }]}
        fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
        transform={value => ({ image: value?.url ? value.url : value })}
        convertValue={value => value?.url ? value : value ? ({ url: value }) : null}
      />

      <ProForm.Group>
        <ProFormText
          width='md'
          name='title'
          label='标题'
          rules={[{ required: true }]}
        />

        <ProFormSelect
          width='md'
          name='type'
          label='类型'
          rules={[{ required: true }]}
          valueEnum={store.config.getConstantEnums('InfoTypes')}
          convertValue={value => value.toString()}
        />
      </ProForm.Group>

      <ProFormDatePicker
        width='sm'
        name='releaseTime'
        label='发布时间'
        rules={[{ required: true }]}
      />

      <ProFormTextArea
        width='xl'
        name='desc'
        label='简介'
        fieldProps={{ rows: 6 }}
        rules={[{ required: true }]}
      />

      <Template
        name='content'
        label='内容'
        rules={[{ required: true }]}
        fieldProps={{ init: store.oss.init, handler: (file: File) => store.oss.upload(file) }}
      />
    </ProForm>
  </div>
}

function List ({ store }) {
  const form = React.useRef<any>()
  const enums = store.config.getConstantEnums('InfoTypes')

  const columns = [
    {
      title: '图片',
      dataIndex: 'image',
      hideInSearch: true,
      render: e => <img className='w-16 h-16 object-cover' src={e}/>,
    },
    {
      title: '类型',
      dataIndex: 'type',
      valueType: 'radioButton',
      initialValue: '1',
      valueEnum: enums,
      formItemProps: () => ({ label: false }),
      fieldProps: () => ({ placeholder: '根据资讯类型搜索' }),
    },
    {
      title: '是否上线',
      dataIndex: 'online',
      hideInSearch: true,
      render: e => e == 'yes' ? '上线中' : '已下线',
    },
    {
      title: '标题',
      dataIndex: 'title',
      hideInSearch: true,
      render: elm => <span title={elm}>{elm?.length > 30 ? elm.slice(0, 30) + '...' : elm}</span>,
    },
    {
      title: '简介',
      dataIndex: 'desc',
      hideInSearch: true,
      render: elm => <span title={elm}>{elm?.length > 40 ? elm.slice(0, 40) + '...' : elm}</span>,
    },
  ]

  const actions = [
    {
      name: '创建',
      type: 'normal',
      onClick: () => store.navigate.relative(`/create`),
    },
  ]

  const rowActions = [
    {
      name: '修改',
      onClick: data => store.navigate.relative(`/${data.id}/update`),
    },
    {
      name: '上线',
      confirmType: 'modal',
      confirmMessage: '您确定上线这条资讯吗？',
      visible: e => e.online == 'no',
      onConfirm: (data, handles) => service(api).online({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      name: '下线',
      confirmType: 'modal',
      confirmMessage: '您确定下线这条资讯吗？',
      visible: e => e.online == 'yes',
      onConfirm: (data, handles) => service(api).offline({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      danger: true,
      name: '删除',
      confirmType: 'modal',
      confirmMessage: '您确定删除这条资讯吗？',
      onConfirm: (data, handles) => service(api).delete({ id: data.id }).then(() => handles.current.reload()),
    },
  ]

  return <div className='m-4'>
    <Table
      title='资讯管理'
      request={fetchData}
      controls={form}
      columns={columns}
      actions={actions}
      rowActions={rowActions}
      form={{ onValuesChange: e => form.current.submit() }}
      search={{ labelWidth: 'auto', span: store.config.responsive }}
    />
  </div>
}

export default function View (prefix, app) {
  return app
    .useRoute(`${prefix}/:id/update`, Update)
    .useRoute(`${prefix}/create`, Create)
    .useRoute(`${prefix}/`, List)
}
