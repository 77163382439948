export default api => ({
  list (options?) {
    return api.get(`/product/selectByType`, options)
  },

  detail (options?) {
    return api.get(`/product/selectById`, options)
  },

  create (options?) {
    return api.post(`/product/save`, options)
  },

  update (options?) {
    return api.post(`/product/update`, options)
  },

  delete (options?) {
    return api.post(`/product/delete`, options)
  },

  online(options?) {
    return api.post(`/product/online`, options)
  },

  offline(options?) {
    return api.post(`/product/offline`, options)
  },

  topping(options?) {
    return api.post(`/product/topping`, options)
  },
})
