import { useSessionStorageState } from 'ahooks'

export default function useIdentity() {
  const [ uid, uidDispatch ] = useSessionStorageState<string>('admin:uid')
  const [ token, tokenDispatch ] = useSessionStorageState<string>('admin:token')

  const clear = R.compose(
    R.tap(uidDispatch),
    R.tap(tokenDispatch),
  )

  const dispatch = R.cond([
    [R.equals('uid'), R.always(uidDispatch)],
    [R.equals('token'), R.always(tokenDispatch)],
  ])

  return {
    uid,
    token,
    clear: () => clear(undefined),
    dispatch: (type: string, data: any) => R.call(dispatch(type), data),
  }
}
