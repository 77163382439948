export default api => ({
  list (options?) {
    return api.get(`/contact/selectPage`, options)
  },

  read (options?) {
    return api.post(`/contact/read`, options)
  },

  delete (options?) {
    return api.post(`/contact/delete`, options)
  },
})
