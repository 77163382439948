import { useRequest } from 'ahooks'

import { Upload } from 'antd'
import { ProFormItem } from '@ant-design/pro-form'

function UploadButton ({ handler, readonly, value, onChange }) {
  const request = useRequest((file) => handler(file), { manual: true })

  if(readonly) {
    return <div className='relative w-32 h-32 cursor-pointer p-0.5 border border-solid border-gray-400'>
      <section className='h-full'>
        <img src={value.url} className='object-cover h-full w-full'/>
      </section>
    </div>
  }

  if(value) {
    return <div className='relative w-32 h-32 cursor-pointer p-0.5 border border-solid border-gray-400'>
      <section className='h-full'>
        <img src={value.url} className='object-cover h-full w-full'/>
      </section>
      <section className='absolute top-0 left-0 text-2xl flex items-center justify-center space-x-2 w-full h-full opacity-0 transition-opacity duration-300 hover:opacity-90 bg-gray-600'>
        <div className='fa fa-trash-o transition-color duration-300 text-white hover:text-red-600' onClick={() => onChange(null)}></div>
      </section>
    </div>
  }

  if(request.loading) {
    return <div className='w-32 h-32 flex flex-col items-center justify-center space-y-2 cursor-wait border border-dashed border-gray-400'>
      <div className='fa fa-spinner animate-spin'></div>
      <div>上传中...</div>
    </div>
  }

  return <Upload showUploadList={false} customRequest={event => request.runAsync(event.file).then(result => onChange(result))}>
    <div className='w-32 h-32 flex flex-col items-center justify-center space-y-2 cursor-pointer border border-dashed border-gray-400 hover:border-blue-600 hover:text-blue-600'>
      <div className='fa fa-upload'></div>
      <div className='text-sm'>点击上传</div>
      <div className='text-xs'>或拖动图片到此处</div>
    </div>
  </Upload>
}

export default function View ({ max, fieldProps, ...rest }) {
  return <ProFormItem {...rest}>
    <UploadButton max={max} {...fieldProps} />
  </ProFormItem>
}
