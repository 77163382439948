import React from 'react'
import Table from '@sar/component/src/table'

import api from '@sar/http'
import service from 'services/contact'

async function fetchData(params) {
  const result = await service(api).list({
    params: Object.assign(params, {
      page: params.current,
      size: params.pageSize,
    })
  })

  return {
    success: true,
    data: result.data.list,
    total: result.data.count,
  }
}

export default function View ({ store }) {
  const form = React.useRef<any>()

  const columns = [
    {
      title: '状态',
      dataIndex: 'status',
      valueType: 'radioButton',
      initialValue: 'no',
      valueEnum: { no: '未读', yes: '已读' },
      formItemProps: () => ({ label: false }),
      fieldProps: () => ({ placeholder: '根据状态搜索' }),
      render: (elm, data) => data.status == 'yes' ? <span className='inline-block py-1 px-3 rounded text-green-600 bg-green-100'>已读</span> : <span className='inline-block py-1 px-3 rounded text-gray-600 bg-gray-100'>未读</span>
    },
    {
      title: '用户姓名',
      dataIndex: 'name',
      hideInSearch: true,
    },
    {
      title: '用户手机号',
      dataIndex: 'phone',
      hideInSearch: true,
    },
    {
      title: '公司名称',
      dataIndex: 'company',
      hideInSearch: true,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      hideInSearch: true,
    },
    {
      title: '详情',
      dataIndex: 'desc',
      hideInSearch: true,
    },
  ]

  const rowActions = [
    {
      name: '已读',
      confirmType: 'pop',
      confirmMessage: '这条消息确定标记为已读吗？',
      visible: e => e.status == 'no',
      onConfirm: (data, handles) => service(api).read({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      danger: true,
      name: '删除',
      confirmType: 'modal',
      confirmMessage: '您确定删除这条消息吗？',
      onConfirm: (data, handles) => service(api).delete({ id: data.id }).then(() => handles.current.reload()),
    },
  ]

  return <div className='m-4'>
    <Table
      title='留言管理'
      controls={form}
      request={fetchData}
      columns={columns}
      rowActions={rowActions}
      form={{ onValuesChange: e => form.current.submit() }}
      search={{ labelWidth: 'auto', span: store.config.responsive }}
    />
  </div>
}
