import Editor from 'react-quill'

class Blot extends Quill.import('blots/block/embed') {
  static create = data => R.tap(elm => {

    if(R.is(String, data)) {
      elm.setAttribute('src', data)
    }

    else {
      elm.setAttribute('alt', data.alt)
      elm.setAttribute('src', data.url)
      elm.setAttribute('style', data.style)
      elm.setAttribute('width', data.width)
    }

  }, super.create())

  static value = elm => ({

    alt: elm.getAttribute('alt'),
    url: elm.getAttribute('src'),
    style: elm.getAttribute('style'),
    width: elm.getAttribute('width'),

  })
}

Quill.register(Object.assign(Blot, {
  tagName: 'img',
  blotName: 'image',
}))

export default function View ({ init, value, handler, onChange }) {
  const modules = React.useMemo(() => ({
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [
          { 'list': 'ordered'},
          { 'list': 'bullet' },
        ],

        [
          { 'script': 'sub'},
          { 'script': 'super' },
        ],

        [
          { 'indent': '-1'},
          { 'indent': '+1' },
        ],

        [
          { 'direction': 'rtl' },
        ],

        [
          { 'size': ['small', false, 'large', 'huge'] },
        ],

        [
          { 'header': [1, 2, 3, 4, 5, 6, false] },
        ],

        [
          { 'color': [] }, { 'background': [] },
        ],

        [
          { 'font': [] },
        ],

        [
          { 'align': [] },
        ],

        ['link', 'image'],
        ['clean']
      ],
    },

    imageResize: {

    },

    imageUploader: {
      upload: file => handler(file).then(R.prop('url')),
    },
  }), [ init ])

  if(init == false) {
    return <div>初始化中...</div>
  }

  return <Editor
    theme='snow'
    modules={modules}
    value={value}
    onChange={onChange}
    children={<div style={{ height: 600 }} />}
  />
}
