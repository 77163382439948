import React from 'react'
import ReactDOM from 'react-dom'

import Index from 'views/index'
import Login from 'views/login'

import Cache from 'plugins/cache'
import Layout from 'plugins/layout'

import http from '@sar/http'
import engine from '@sar/framework'

import config from 'hooks/config'
import external from 'hooks/external'
import identity from 'hooks/identity'
import navigate from 'hooks/navigate'
import oss from 'hooks/oss'

import authorize from 'middlewares/authorize'
import notification from 'middlewares/notification'

Quill.register('modules/imageResize', R.has('default', ImageResize) ? R.prop('default', ImageResize) : ImageResize)
Quill.register('modules/imageUploader', R.has('default', ImageUploader) ? R.prop('default', ImageUploader) : ImageUploader)

http.useProxy('request', authorize)
http.useProxy('response', notification)

http.setting('base', APP_ENV.api)
http.setting('adapter', APP_ENV.adapter)

const APP = (
  engine
    .create()
    .set((app: engine) => app.usePlugin(Cache))
    .set((app: engine) => app.usePlugin(Layout))
    .set((app: engine) => app.useStore('oss', oss))
    .set((app: engine) => app.useStore('config', config))
    .set((app: engine) => app.useStore('identity', identity))
    .set((app: engine) => app.useStore('navigate', navigate))
    .set((app: engine) => app.useStore('external', external))
    .set((app: engine) => app.useRoute('/login', Login))
    .set((app: engine) => app.useRouter('/', Index))
    .set((app: engine) => app.useRedirect('/', '/login'))
    .set((app: engine) => app.useRedirect('/*', '/login'))
)

ReactDOM.render(<APP.DOM store={APP} />, document.getElementById('root'))
