export default [
  {
    key: '/setting',
    label: '基础设置',
    icon: <span className='fa fa-cogs' />,
    children: [
      {
        icon: null,
        label: '网站图片',
        key: '/setting/banners',
      },
      {
        icon: null,
        label: '地址管理',
        key: '/setting/location',
      }
    ]
  },
  {
    key: '/product',
    label: '产品中心',
    icon: <span className='fa fa-building-o' />,
    children: [
      {
        icon: null,
        label: '产品大类',
        key: '/product/class',
      },
      {
        icon: null,
        label: '产品管理',
        key: '/product/manager',
      },
    ]
  },
  {
    key: '/information',
    label: '资讯中心',
    icon: <span className='fa fa-commenting-o' />,
    children: [
      {
        icon: null,
        label: '资讯管理',
        key: '/information/manager',
      },
    ]
  },
  {
    key: '/server',
    label: '服务中心',
    icon: <span className='fa fa-compass' />,
    children: [
      {
        icon: null,
        label: '在线留言',
        key: '/server/message',
      },
      {
        icon: null,
        label: '资料下载',
        key: '/server/download',
      },
    ]
  },
]
