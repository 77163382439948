import React from 'react'
import Table from '@sar/component/src/table'

import api from '@sar/http'
import service from 'services/product-type'

import { Upload } from 'components'
import { ModalForm, ProFormText, ProFormTextArea } from '@ant-design/pro-form'

function Create({ store, visible, handles, onVisibleChange }) {
  return <ModalForm
    width={600}
    title='创建大类'
    visible={visible}
    onVisibleChange={onVisibleChange}
    modalProps={{ maskClosable: false, destroyOnClose: true }}
    onFinish={params => service(api).create(params).then(() => handles.current.reload()).then(R.T)}
  >
    <ProFormText
      name='title'
      label='名称'
      rules={[{ required: true }]}
    />

    <ProFormTextArea
      name='desc'
      label='简介'
      fieldProps={{ rows: 8 }}
      rules={[{ required: true }]}
    />

    <Upload
      name='image'
      label='图片(推荐宽高 600 * 580)'
      max={1}
      rules={[{ required: true }]}
      fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
      transform={value => ({ image: value?.url ? value.url : value })}
      convertValue={value => value?.url ? value : value ? ({ url: value }) : null}
    />
  </ModalForm>
}

function Update({ data, store, visible, handles, onVisibleChange }) {
  return <ModalForm
    width={600}
    title='修改大类'
    visible={visible}
    onVisibleChange={onVisibleChange}
    modalProps={{ maskClosable: false, destroyOnClose: true }}
    request={() => service(api).detail({ params: { id: data.id } }).then(R.prop('data'))}
    onFinish={e => service(api).update({ ...e, id: data.id }).then(() => handles.current.reload()).then(R.T)}
  >
    <ProFormText
      name='title'
      label='名称'
      rules={[{ required: true }]}
    />

    <ProFormTextArea
      name='desc'
      label='简介'
      fieldProps={{ rows: 8 }}
      rules={[{ required: true }]}
    />

    <Upload
      name='image'
      label='图片(推荐宽高 600 * 580)'
      max={1}
      rules={[{ required: true }]}
      fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
      transform={value => ({ image: value?.url ? value.url : value })}
      convertValue={value => value?.url ? value : value ? ({ url: value }) : null}
    />
  </ModalForm>
}

async function fetchData(params) {
  const result = await service(api).list({
    params: {
      page: params.current,
      size: params.pageSize,
    }
  })

  return {
    success: true,
    data: result.data.list,
    total: result.data.count,
  }
}

export default function View ({ store }) {
  const columns = [
    {
      title: '图片',
      dataIndex: 'image',
      hideInSearch: true,
      render: e => <img className='w-16 h-16 object-cover' src={e}/>,
    },
    {
      title: '标题',
      dataIndex: 'title',
      hideInSearch: true,
    },
    {
      title: '是否上线',
      dataIndex: 'online',
      hideInSearch: true,
      render: e => e == 'yes' ? '上线中' : '已下线',
    },
    {
      title: '简介',
      dataIndex: 'desc',
      hideInSearch: true,
      render: e => <pre className='m-0'>{e}</pre>,
    },
  ]

  const actions = [
    {
      name: '创建',
      type: 'trigger',
      form: props => <Create {...props} store={store} />,
    },
  ]

  const rowActions = [
    {
      name: '修改',
      form: props => <Update {...props} store={store} />,
    },
    {
      name: '置顶',
      confirmType: 'pop',
      confirmMessage: '您确定置顶这个产品大类吗？',
      onConfirm: (data, handles) => service(api).topping({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      name: '上线',
      confirmType: 'modal',
      confirmMessage: '您确定上线这个产品大类吗？',
      visible: e => e.online == 'no',
      onConfirm: (data, handles) => service(api).online({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      name: '下线',
      confirmType: 'modal',
      confirmMessage: '您确定下线这个产品大类吗？',
      visible: e => e.online == 'yes',
      onConfirm: (data, handles) => service(api).offline({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      danger: true,
      name: '删除',
      confirmType: 'modal',
      confirmMessage: '您确定删除这个产品大类吗？',
      onConfirm: (data, handles) => service(api).delete({ id: data.id }).then(() => handles.current.reload()),
    },
  ]

  return <div className='m-4'>
    <Table
      title='产品大类'
      request={fetchData}
      columns={columns}
      actions={actions}
      rowActions={rowActions}
    />
  </div>
}
