import Information from './information'
import Product from './product'
import Server from './server'
import Setting from './setting'

export default function(prefix, app) {
  return app
    .useRouter(`${prefix}/information`, Information)
    .useRouter(`${prefix}/product`, Product)
    .useRouter(`${prefix}/server`, Server)
    .useRouter(`${prefix}/setting`, Setting)
}
