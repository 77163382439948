import { useRequest } from 'ahooks'

import { Upload, Button } from 'antd'
import { ProFormItem } from '@ant-design/pro-form'

function UploadFile ({ max, handler, readonly, value = [], onChange }) {
  const request = useRequest((file) => handler(file), { manual: true })

  const files = value.map(elm => {
    if(readonly) {
      return <div key={elm.url} title={elm.name} className='px-2 py-0.5 border border-purple-600 bg-purple-100 rounded-sm flex items-center justify-center space-x-2'>
        <span className='text-purple-800'>{elm.name}</span>
      </div>
    }

    return <div key={elm.url} title={elm.name} className='px-2 py-0.5 border border-purple-600 bg-purple-100 rounded-sm flex items-center justify-center space-x-2'>
      <span className='text-purple-800'>{elm.name}</span>
      <span className='text-purple-800 hover:text-purple-600 fa fa-times cursor-pointer' onClick={() => onChange(value.filter(e => e.url != elm.url))}></span>
    </div>
  })

  return <div className='flex flex-wrap items-center space-x-4'>
    {files}
    {value?.length >= max ? null : (
      <Upload showUploadList={false} customRequest={e => request.runAsync(e.file).then(e => onChange([ ...value, e ]))}>
        <Button ghost type='primary' loading={request.loading} icon={<div className='fa fa-upload pr-2'/>}>选择文件</Button>
      </Upload>
    )}
  </div>
}

function UploadImage ({ max, handler, readonly, value, onChange }) {
  const request = useRequest((file) => handler(file), { manual: true })

  const covers = R.defaultTo([], value).map(data => {
    if(readonly) {
      return <div key={data.url} className='relative w-32 h-32 cursor-pointer p-0.5 border border-solid border-gray-400'>
        <div className='h-full'>
          <img src={data.url} className='object-cover h-full w-full'/>
        </div>
      </div>
    }

    return <div key={data.url} className='relative w-32 h-32 cursor-pointer p-0.5 border border-solid border-gray-400'>
      <div className='h-full'>
        <img src={data.url} className='object-cover h-full w-full'/>
      </div>
      <div className='absolute top-0 left-0 text-2xl flex items-center justify-center space-x-2 w-full h-full opacity-0 transition-opacity duration-300 hover:opacity-90 bg-gray-600'>
        <div className='fa fa-trash-o transition-color duration-300 text-white hover:text-red-600' onClick={() => onChange(value.filter(e => e.url != data.url))}></div>
      </div>
    </div>
  })

  return <div className='flex flex-wrap items-center space-x-4'>
    {covers}
    {request.loading ? (
      <div className='w-32 h-32 flex flex-col items-center justify-center space-y-2 cursor-wait border border-dashed border-gray-400'>
        <div className='fa fa-spinner animate-spin'></div>
        <div>上传中...</div>
      </div>
    ) : value?.length >= max ? null : (
      <Upload showUploadList={false} customRequest={e => request.runAsync(e.file).then(e => onChange([ ...R.defaultTo([], value), e ]))}>
        <div className='w-32 h-32 flex flex-col items-center justify-center space-y-2 cursor-pointer border border-dashed border-gray-400 hover:border-blue-600 hover:text-blue-600'>
          <div className='fa fa-upload'></div>
          <div className='text-sm'>点击上传</div>
          <div className='text-xs'>或拖动图片到此处</div>
        </div>
      </Upload>
    )}
  </div>
}

export default function View ({ max, type, fieldProps, ...rest }) {
  return <ProFormItem {...rest}>
    {type == 'file'
      ? <UploadFile max={max} {...fieldProps} />
      : <UploadImage max={max} {...fieldProps} />
    }
  </ProFormItem>
}
