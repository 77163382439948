import { v4 } from 'uuid'

import { Button } from 'antd'
import { ProFormItem } from '@ant-design/pro-form'

export default function View ({ fieldProps, ...rest }) {
  return <ProFormItem {...rest}>{React.createElement(({ value = [], onChange }) => {
    const list = R.isEmpty(value) ? R.of({ key: v4() }) : value

    return <table className='border-collapse border border-gray-600'>
      <thead className='bg-gray-300'>
        <tr>
          <th className='p-2 border border-gray-600'>{R.nth(0, fieldProps.cols)}</th>
          <th className='p-2 border border-gray-600'>{R.nth(1, fieldProps.cols)}</th>
          <th className='p-2 border border-gray-600'>操作</th>
        </tr>
      </thead>
      <tbody>
        {list.map((elm, i) => {
          return <tr key={elm.id || elm.key}>
            <td className='p-2 border border-gray-600'>
              <textarea className='p-2 py-1 w-40 resize-none outline-none focus:ring focus:ring-indigo-300' rows={2} value={elm.name} onChange={e => onChange(R.update(i, { ...elm, name: e.target.value }, list))} />
            </td>
            <td className='p-2 border border-gray-600'>
              <textarea className='p-2 py-1 w-96 resize-none outline-none focus:ring focus:ring-indigo-300' rows={2} value={elm.value} onChange={e => onChange(R.update(i, { ...elm, value: e.target.value }, list))}/>
            </td>
            <td className='p-2 border border-gray-600 space-x-2'>
              <Button ghost type='primary' size='small' onClick={e => onChange(R.insert(R.inc(i), { key: v4() }, list))}>新增</Button>
              <Button danger size='small' onClick={e => onChange(R.remove(i, 1, value))}>删除</Button>
            </td>
          </tr>
        })}
      </tbody>
  </table>
  })}</ProFormItem>
}
