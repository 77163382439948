import { Modal } from 'antd'

export default function notification(response) {
  if(response.code != 200) {
    Modal.warning({
      centered: true,
      title: '温馨提示',
      content: response.data.msg,
    })

    return Promise.reject(`error with ${response.code}.`)
  }

  if(response.data.success == false) {
    Modal.warning({
      centered: true,
      title: '温馨提示',
      content: response.data.msg,
    })

    return Promise.reject(`error with ${response.data.msg}.`)
  }

  return Promise.resolve(response.data)
}
