import { useRequest } from 'ahooks'
import { useRef } from 'react'

import { Spin, Button, Input, message } from 'antd'
import { APILoader, Map, InfoWindow, AutoComplete } from '@uiw/react-amap'

export default function View ({ store }) {
  const elm = useRef<any>()
  const request = useRequest<any, any>(() => store.external.api.get('/config/selectAddress').then(R.prop('data')))

  if(request.loading) {
    return <div className='m-4 h-full flex items-center justify-center bg-white'>
      <Spin tip='初始化中...' />
    </div>
  }

  return <APILoader akay='ba97f34980e4dc64b68048e093514747'>
    <div className='p-4 h-full relative'>
      <Map zoom={16} center={request.data?.position}>
        <InfoWindow
          visiable
          isCustom
          offset={[0, -24]}
          position={request.data?.position}
          content={`<div class='p-4 w-64 rounded-sm text-center text-sm bg-white relative shadow flex flex-col items-center justify-center space-y-2'><div class='text-blue-900 font-bold text-base'>${request.data?.desc.split('-')[0]}</div><div class='text-gray-800'>${request.data?.desc.split('-')[1]}</div><div class='w-0 h-0 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full' style='border-width: 16px 8px 8px; border-style: solid; border-color: white transparent transparent; border-image: initial;'></div></div>`}
        />
      </Map>

      <div className='p-4 flex items-center justify-center bg-white space-x-3 absolute top-10 right-10'>
        <Input
          id='elm'
          ref={elm}
          placeholder='输入地址查询, 如武汉大学'
          style={{ width: 300 }}
        />

        <Button
          type='primary'
          children='保存地址'
          onClick={() => store.external.api.post('/config/saveAddress', request.data).then(() => message.success('保存成功'))}
        />

        <AutoComplete
          input='elm'
          onSelect={(e: any) => request.mutate({ desc: e.poi.name + '-' + e.poi.district + e.poi.address, position: [ e.poi.location.lng, e.poi.location.lat ] })}
        />
      </div>
    </div>
  </APILoader>
}
