import React from 'react'
import Table from '@sar/component/src/table'

import api from '@sar/http'
import service from 'services/product'

import { UploadMultiple, Tables } from 'components'
import ProForm, { ProFormText, ProFormTextArea, ProFormSelect } from '@ant-design/pro-form'

async function fetchData(params) {
  const result = await service(api).list({
    params: {
      type: params.type,
      page: params.current,
      size: params.pageSize,
    }
  })

  return {
    success: true,
    data: result.data.list,
    total: result.data.totalRow,
  }
}

function Create({ store }) {
  return <div className='m-4 p-4 bg-white'>
    <ProForm scrollToFirstError title='创建产品' onFinish={params => service(api).create(params).then(e => store.navigate.absolute('/product/manager'))}>
      <ProForm.Group>
        <ProFormSelect
          width='md'
          name='type'
          label='大类'
          rules={[{ required: true }]}
          request={() => api.get('/productType/selectAll').then(R.path([ 'data', 'list' ])).then(R.map<any, any>(e => ({ label: e.title, value: e.id })))}
        />

        <ProFormText
          width='md'
          name='title'
          label='名称'
          rules={[{ required: true }]}
        />
      </ProForm.Group>

      <ProFormText
        width='md'
        name='scene'
        label='应用场景'
        rules={[{ required: true }]}
      />

      <ProFormTextArea
        width={900}
        name='desc'
        label='产品简介'
        fieldProps={{ rows: 6 }}
        rules={[{ required: true }]}
      />

      <ProFormTextArea
        width={900}
        name='content'
        label='产品详情'
        fieldProps={{ rows: 16 }}
        rules={[{ required: true }]}
      />

      <UploadMultiple
        type='image'
        name='productImages'
        label='图片(推荐宽高 628 * 480)'
        max={6}
        rules={[{ required: true }]}
        fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
        transform={value => ({ productImages: value.map(R.pick([ 'url' ])) })}
      />

      <UploadMultiple
        type='file'
        name='productFiles'
        label='产品文件'
        max={4}
        fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
        transform={value => ({ productFiles: value.map(R.pick([ 'url', 'name' ])).map(elm => ({ ...elm, fileName: elm.name })) })}
      />

      <Tables
        name='specification'
        label='技术规格'
        rules={[{ required: true }]}
        fieldProps={{ cols: [ '名称', '内容' ] }}
        transform={value => ({ specification: value.map(R.pick([ 'name', 'value' ])) })}
      />
    </ProForm>
  </div>
}

function Update({ store, route }) {
  return <div className='m-4 p-4 bg-white'>
    <ProForm scrollToFirstError title='创建产品' request={() => service(api).detail({ params: { id: route.params.id } }).then(R.prop('data'))} onFinish={e => service(api).update({ ...e, id: route.params.id }).then(e => store.navigate.absolute('/product/manager'))}>
      <ProForm.Group>
        <ProFormSelect
          width='md'
          name='type'
          label='大类'
          rules={[{ required: true }]}
          request={() => api.get('/productType/selectAll').then(R.path([ 'data', 'list' ])).then(R.map<any, any>(e => ({ label: e.title, value: e.id })))}
        />

        <ProFormText
          width='md'
          name='title'
          label='名称'
          rules={[{ required: true }]}
        />
      </ProForm.Group>

      <ProFormText
        width='md'
        name='scene'
        label='应用场景'
        rules={[{ required: true }]}
      />

      <ProFormTextArea
        width={900}
        name='desc'
        label='产品简介'
        fieldProps={{ rows: 6 }}
        rules={[{ required: true }]}
      />

      <ProFormTextArea
        width={900}
        name='content'
        label='产品详情'
        fieldProps={{ rows: 16 }}
        rules={[{ required: true }]}
      />

      <UploadMultiple
        type='image'
        name='productImages'
        label='图片(推荐宽高 628 * 480)'
        max={6}
        rules={[{ required: true }]}
        fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
        transform={value => ({ productImages: value.map(R.pick([ 'id', 'url' ])) })}
      />

      <UploadMultiple
        type='file'
        name='productFiles'
        label='产品文件'
        max={4}
        fieldProps={{ handler: (file: File) => store.oss.upload(file) }}
        convertValue={value => value.map(elm => ({ ...elm, name: elm.fileName ? elm.fileName : elm.name }))}
        transform={value => ({ productFiles: value.map(R.pick([ 'id', 'url', 'name', 'fileName' ])).map(elm => ({ ...elm, fileName: elm.name ? elm.name : elm.fileName })) })}
      />

      <Tables
        name='specification'
        label='技术规格'
        rules={[{ required: true }]}
        fieldProps={{ cols: [ '名称', '内容' ] }}
        transform={value => ({ specification: value.map(R.pick([ 'id', 'name', 'value' ])) })}
      />
    </ProForm>
  </div>
}

function List ({ store }) {
  const columns = [
    {
      title: '图片',
      dataIndex: 'url',
      hideInSearch: true,
      render: e => <img className='w-16 h-16 object-cover' src={e} />,
    },
    {
      title: '名称',
      dataIndex: 'title',
      hideInSearch: true,
    },
    {
      title: '是否上线',
      dataIndex: 'online',
      hideInSearch: true,
      render: e => e == 'yes' ? '上线中' : '已下线',
    },
    {
      title: '类型',
      dataIndex: 'type',
      valueType: 'select',
      formItemProps: () => ({ label: false }),
      fieldProps: () => ({ placeholder: '根据产品大类搜索' }),
      request: () => api.get('/productType/selectAll').then(R.path([ 'data', 'list' ])).then(R.map<any, any>(e => ({ label: e.title, value: e.id }))),
    },
    {
      title: '应用场景',
      dataIndex: 'scene',
      hideInSearch: true,
      render: elm => <span title={elm}>{elm?.length > 30 ? elm.slice(0, 30) + '...' : elm}</span>,
    },
    {
      title: '简介',
      dataIndex: 'desc',
      hideInSearch: true,
      render: elm => <span title={elm}>{elm?.length > 60 ? elm.slice(0, 60) + '...' : elm}</span>,
    },
  ]

  const actions = [
    {
      name: '创建',
      type: 'normal',
      onClick: () => store.navigate.relative(`/create`),
    },
  ]

  const rowActions = [
    {
      name: '修改',
      onClick: data => store.navigate.relative(`/${data.id}/update`),
    },
    {
      name: '置顶',
      confirmType: 'pop',
      confirmMessage: '您确定置顶这个产品吗？',
      onConfirm: (data, handles) => service(api).topping({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      name: '上线',
      confirmType: 'modal',
      confirmMessage: '您确定上线这个产品吗？',
      visible: e => e.online == 'no',
      onConfirm: (data, handles) => service(api).online({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      name: '下线',
      confirmType: 'modal',
      confirmMessage: '您确定下线这个产品吗？',
      visible: e => e.online == 'yes',
      onConfirm: (data, handles) => service(api).offline({ id: data.id }).then(() => handles.current.reload()),
    },
    {
      danger: true,
      name: '删除',
      confirmType: 'modal',
      confirmMessage: '您确定删除这个产品吗？',
      onConfirm: (data, handles) => service(api).delete({ id: data.id }).then(() => handles.current.reload()),
    },
  ]

  return <div className='m-4'>
    <Table
      title='产品管理'
      request={fetchData}
      columns={columns}
      actions={actions}
      rowActions={rowActions}
      search={{ labelWidth: 'auto', span: store.config.responsive }}
    />
  </div>
}

export default function View (prefix, app) {
  return app
    .useRoute(`${prefix}/:id/update`, Update)
    .useRoute(`${prefix}/create`, Create)
    .useRoute(`${prefix}/`, List)
}
