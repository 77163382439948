import { Menu } from 'antd'
import { useReactive } from 'ahooks'
import { useRouteMatch, useHistory } from 'react-router'

export default function View ({ menus }) {
  const route = useRouteMatch()
  const history = useHistory()

  const path = R.compose(
    R.replace(/\/$/, ''),
    R.head,
    R.split('?'),
    R.prop('url'),
  )

  const current = R.compose(
    R.of,
    R.find(str => R.test(new RegExp(str, 'g'), path(route))),
    R.map<object, string>(R.prop('key')),
    R.flatten,
    R.map<object, object[]>(R.prop('children')),
  )

  const parent = R.find(
    // @ts-ignore
    R.compose(
      R.find(str => R.test(new RegExp(str, 'g'), path(route))),
      R.map<object, string>(R.prop('key')),
      R.prop('children'),
    )
  )

  const status = useReactive({
    // @ts-ignore
    openKeys: [parent(menus)?.key],
  })

  return <Menu
    theme='dark'
    mode='inline'
    items={menus}
    openKeys={status.openKeys}
    selectedKeys={current(menus)}
    onClick={item => history.push(item.key)}
    onOpenChange={keys => Object.assign(status, { openKeys: keys })}
  />
}
