export default api => ({
  list (options?) {
    return api.get(`/news/selectPage`, options)
  },

  detail (options?) {
    return api.get(`/news/selectById`, options)
  },

  create (options?) {
    return api.post(`/news/save`, options)
  },

  update (options?) {
    return api.post(`/news/update`, options)
  },

  delete (options?) {
    return api.post(`/news/delete`, options)
  },

  online(options?) {
    return api.post(`/news/online`, options)
  },

  offline(options?) {
    return api.post(`/news/offline`, options)
  },
})
