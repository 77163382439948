export default api => ({
  list (options?) {
    return api.get(`/productFile/selectPage`, options)
  },

  create (options?) {
    return api.post(`/productFile/save`, options)
  },

  update (options?) {
    return api.post(`/productFile/update`, options)
  },

  delete (options?) {
    return api.post(`/productFile/delete`, options)
  },
})
