export default api => ({
  list (options?) {
    return api.get(`/productType/selectAll`, options)
  },

  detail (options?) {
    return api.get(`/productType/selectById`, options)
  },

  create (options?) {
    return api.post(`/productType/save`, options)
  },

  update (options?) {
    return api.post(`/productType/update`, options)
  },

  delete (options?) {
    return api.post(`/productType/delete`, options)
  },

  online(options?) {
    return api.post(`/productType/online`, options)
  },

  offline(options?) {
    return api.post(`/productType/offline`, options)
  },

  topping(options?) {
    return api.post(`/productType/topping`, options)
  },
})
